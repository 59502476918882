let INPUT_ESTIMATED_USAGE_LABEL = 'project_fields[consumo_presunto]';
let BUTTON_LEVEL_USAGE = 'default_usage';
let INPUT_OFFER_BRAND_LABEL = 'project_fields[fornitore_vendita]';
let INPUT_OFFER_NAME_LABEL = 'project_fields[nome_offerta]';
let INPUT_IP_LABEL = 'project_fields[ip]';
let INPUT_SOURCE_URL_LABEL = 'project_fields[source_url]';
let INPUT_USER_AGENT_LABEL = 'project_fields[user_agent]';
let INPUT_GCLID_LABEL = 'project_fields[google_click_id]';
let INPUT_AZIONE_LABEL = 'project_fields[azione]';
let INPUT_DOCUMENTATION_LABEL = 'project_fields[link_documento_precontrattuale]';
let INPUT_PRIVACY_CONDITIONS_LABEL = 'privacy[termini_condizioni]';
let INPUT_PRIVACY_NEWSLETTER_LABEL = 'privacy[newsletter_cloudcare]';
let INPUT_RESIDENCE_CITY = 'project_fields[residenza_comune_text]';
let INPUT_RESIDENCE_PROVINCE = 'project_fields[residenza_provincia_text]';
let INPUT_RESIDENCE_CAP = 'project_fields[residenza_cap_text]';
let INPUT_RESIDENCE_ADDRESS = 'project_fields[indirizzo_residenza_civico]';
let INPUT_RESIDENCE_HOUSE_NUMBER = 'project_fields[residenza_indirizzo_text]';
let INPUT_IBAN_FIRSTNAME = 'project_fields[nome_intestatario]';
let INPUT_IBAN_LASTNAME = 'project_fields[cognome_intestatario_iban]';
let INPUT_IBAN_FISCAL_CODE = 'project_fields[codice_fiscale_intestatario_iban]';
let INPUT_IBAN_BORN_OUT = 'project_fields[nato_estero_intestatario_iban]';
let INPUT_ACTUAL_BRAND_PREFIX = 'fornitore_uscente_';
let INPUT_ACTUAL_BRAND_ENERGY = 'fornitore_uscente_luce';
let INPUT_ACTUAL_BRAND_GAS = 'fornitore_uscente_gas';
let INPUT_ACTUAL_BRAND = 'project_fields[fornitore_uscente]';
let VALUE_RESIDENCE_EQ_SUPPLY = 'si';
let VALUE_SENDER_EQ_OWNER = "sono l'intestatario";
let INPUT_TIPO_DI_DOCUMENTO = 'project_fields[tipo_di_documento]';
let INPUT_DOCUMENTO_ENTE_DI_RILASCIO = 'project_fields[documento_ente_di_rilascio]';
let INPUT_DOCUMENTO_DATA_EMISSIONE_TEXT = 'project_fields[documento_data_emissione_text]';
let INPUT_DOCUMENTO_NUMERO_TEXT = 'project_fields[documento_numero_text]';
let WS_CAMPAIGN_VALUE = 'campaign';


let shadowRoot;
window.addEventListener('heyflow-init', (event) => {
    //console.log('heyflow init:', event);
    shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
    addCustomListener();
    loadUserData();
    processMessageFromIframe();
});

function addCustomListener() {
    var element = getUsageElement('luce')
    element.addEventListener(
        'keyup',
        function(event) {
            onChangeUsage('luce')
        },
        false
    );
    element = getUsageElement('gas')
    element.addEventListener(
        'keyup',
        function(event) {
            onChangeUsage('gas')
        },
        false
    );
}

function getUsageElement(type) {
    return shadowRoot.querySelector("section[name='stima-consumo-" + type + "'] input[type='text']");
}

function onChangeUsage(type) {
    let usageElement = getUsageElement(type);
    let usage = parseInt(usageElement.value);
    if(isNaN(usage)) {
        resetButtonDefaultUsage();
        return;
    }
    let lowValue = getUsageLevelValue(type, 0);
    let mediumValue = getUsageLevelValue(type, 1);
    let highValue = getUsageLevelValue(type, 2);
    var obj = getValueAndIndexBasedOnUsageType(type, usage);
    var value = obj.value;
    switch(type) {
        case "gas":
            shadowRoot.querySelectorAll("section[name='stima-consumo-" + type + "'] input[data-answer='" + value +"']")[0].checked = true;
            break;
        case "luce":
            shadowRoot.querySelectorAll("section[name='stima-consumo-" + type + "'] input[data-answer='" + value +"']")[0].checked = true;
            break;
    }
}

function resetButtonDefaultUsage() {
    for(i = 0; i < 3; i++) {
        shadowRoot.querySelectorAll("input[data-label='" + BUTTON_LEVEL_USAGE + "']")[i].checked = false;
    }
}

function getUsageLevelValue(type, index) {
    if(type == "") {
        type = "luce";
    }
    let elements = shadowRoot.querySelectorAll("section[name='stima-consumo-" + type + "'] input[data-label='" + BUTTON_LEVEL_USAGE + "']");
    let element = elements[index];
    return parseInt(element.getAttribute('data-answer'));
}


function getValueAndIndexBasedOnUsageType(type, usage) {
    let lowValue = getUsageLevelValue(type, 0);
    let mediumValue = getUsageLevelValue(type, 1);
    let highValue = getUsageLevelValue(type, 2);
    var index = "";
    var levelLimit = 0;
    switch(type) {
        case "gas":
            if(usage <= lowValue) {
                levelLimit = lowValue;
                index = "basso";
            } else if(usage >= highValue) {
                levelLimit = highValue;
                index = "alto";
            } else {
                levelLimit = mediumValue;
                index = "medio";
            }
            break;
        case "luce":
            if(usage <= lowValue) {
                levelLimit = lowValue;
                index = "basso";
            } else if(usage >= highValue) {
                levelLimit = highValue;
                index = "alto";
            } else {
                levelLimit = mediumValue;
                index = "medio";
            }
            break;
    }
    return {'value': levelLimit, 'index': index};
}

function loadUserData() {
    var url = window.location.href;
    if(url.indexOf("lob:") > 0) {
        url = url.substring(5);
    }
    populateField('start', INPUT_SOURCE_URL_LABEL, url);
    const request = new XMLHttpRequest();
    request.open("GET", "https://api.ipify.org", false);
    request.send(null);
    if (request.status === 200) {
        populateField('start', INPUT_IP_LABEL, request.responseText);
    }
    populateField('start', INPUT_USER_AGENT_LABEL, navigator.userAgent);
    populateField('start', INPUT_PRIVACY_CONDITIONS_LABEL, '1');
    populateField('start', INPUT_PRIVACY_NEWSLETTER_LABEL, '1');
    const regex = new RegExp("(?<=gclid=).*?(?=&|\\s|$|#)", "g");
    const match = window.location.toString().match(regex);
    if (match) {
        populateField('start', INPUT_GCLID_LABEL, match[0]);
    }
}

function populateField(sectionName, dataLabel, valueStr) {
    let element = getField(sectionName, dataLabel);
    //console.log(sectionName, dataLabel, valueStr, element);
    element.value = valueStr;
}

function getField(sectionName, dataLabel) {
    return shadowRoot.querySelector("section[name='" + sectionName +  "'] input[data-label='" + dataLabel + "']");
}

function getSelectField(sectionName, dataLabel) {
    return shadowRoot.querySelector("section[name='" + sectionName +  "'] select[data-label='" + dataLabel + "']");
}

function processMessageFromIframe() {
    window.top.onmessage = function(msg) {
        //console.log("MSG", msg);
        //alert("msg");
        if (checkIframeMessageData(msg)) {
            //console.log("Messaggio confermato");
            if(msg.data.type == 'result') {
                processResultMessage(msg)
            } else if(msg.data.type == 'height') {
                //alert(JSON.stringify(msg.data));
                processHeigtMessage(msg);
            } else if(msg.data.type == 'top') {
                //console.log("TOP HEY");
                processTopMessage(msg)
            }
        }
    };
}

function checkIframeMessageData(msg) {
    if (! (msg.origin.indexOf('https://heyflow.id') === 0 || msg.origin.indexOf('https://www.comparasemplice.it') === 0 || msg.origin.indexOf('https://www3.comparasemplice.it') === 0 )) {
        return false;
    }
    if(! (typeof msg.data === 'object' && msg.data !== null)) {
        return false;
    }
    return ('type'  in msg.data);
}

function processResultMessage(msg) {
    fillInputWithIframeData(msg.data);
    shadowRoot.querySelectorAll("section[name='offerte-lista'] button.continue")[0].click();
}

function processHeigtMessage(msg) {
    var height = msg.data.height;
    height = parseInt(height) + 70;
    height = height + 'px'
    shadowRoot.getElementById('iframe_compara').height = height;
    shadowRoot.getElementById('iframe_compara').style = 'height:' + height;
}

function processTopMessage(msg) {
    //console.log("top message");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    //window.scrollTo(0,0);
}

function fillInputWithIframeData(data) {
    populateField('offerte-lista', INPUT_OFFER_BRAND_LABEL, data.offer_brand);
    populateField('offerte-lista', INPUT_OFFER_NAME_LABEL, data.offer_name);
    populateField('offerte-lista', INPUT_DOCUMENTATION_LABEL, data.documentations);
}

window.addEventListener('heyflow-screen-view', (event) => {
    let page = event.detail.screenName;
    
    console.log("PAGE: " + page);
    //console.log('heyflow screen ' + page + ' view:', event);
    //printSessionStorage();
    switch(page) {
        case 'start':
            openPageStart();
            break;
        case 'offerte-lista':
            fixImageOfferList();
            openPageOfferList(event);
            fillAction();
            break;
        /* interventi Paolo */
        case 'anagrafica':
            console.log('here anagrafica');
            populateField('anagrafica', WS_CAMPAIGN_VALUE, "WS_CU_SWITCH_SELF_LEAD");
            //il valore lo recuperi con getField('anagrafica', 'campaign').value
            console.log("VALUE 1: " + getField('anagrafica', 'campaign').value);
            break;
        case 'indirizzo':
            console.log('here indirizzo');
            populateField('anagrafica', WS_CAMPAIGN_VALUE, "WS_CU_SWITCH_SELF");
            //il valore lo recuperi con getField('anagrafica', 'campaign').value
            console.log("FIELD 2: " + getField('anagrafica', 'campaign').value);
            break;
        case 'fornitura-residenza':
            showFormResidence(true);
            showAddressContinueButton();
            break;
        case 'iban':
            showFormIban(true);
            break;
        case 'confirm':
            fixTextLastPage();
            break;
        case 'stima-consumo-luce':
            hideSupplier(page);
            break;
        case 'stima-consumo-gas':
            hideSupplier(page);
            break;
    }
});

function hideSupplier(page) {
    var action = sessionStorage.getItem("actionType");
    var element = shadowRoot.querySelector("section[name='" + page + "']>div[data-blocktype='select']")
    if(action == 'subentro' || action == 'nuova') {
        changeVisibilyHiddenElement(element, false);
        element.setAttribute('data-initial-required', false);
    } else {
        changeVisibilyHiddenElement(element, true);
        element.setAttribute('data-initial-required', true);
    }
}

function printSessionStorage() {
    console.log("Session", sessionStorage.getItem("supplyType"),
        sessionStorage.getItem("actionType"),
        sessionStorage.getItem("usageValue")
        );
}

function openPageStart() {
    sessionStorage.removeItem("supplyType");
    sessionStorage.removeItem("actionType");
    sessionStorage.removeItem("usageValue");
}

function fixImageOfferList() {
    var images = shadowRoot.querySelector("section[name='offerte-lista']").querySelectorAll("div[data-blocktype~=image]");
    for(var i = 0; i < images.length; i++) {
        images[i].style = 'display:none';
    }
    let type = sessionStorage.getItem("supplyType");
    if(type == "gas") {
        images[1].style = '';
    } else if(type == "luce") {
        images[0].style = '';
    }
}

function openPageOfferList(event) {
    var type = "";
    if(sessionStorage.getItem("supplyType")) {
        type = sessionStorage.getItem("supplyType");
    }
    var url = "https://www.comparasemplice.it/" + type + "-offerte/format/super_self?rp=1&format=super_self&";
    var selector = 'iframe_compara_';
    let supplierElement = getField('offerte-lista', INPUT_ACTUAL_BRAND);
    let supplier = supplierElement.value;
    if(supplier) {
        url += "actual_brand=" + supplier + "&";
    }
    if(sessionStorage.getItem("supplyType") == "luce") {
        url += "kw=3&kwh=";
    } else {
        url += "sm3=";
    }
    url += sessionStorage.getItem("usageValue") + "&type_of_contract=";
    switch(sessionStorage.getItem("actionType")) {
        case "switch":
            url += "c";
            break;
        case "subentro":
            url += "s";
            break;
        case "nuova":
            url += "a";
            break;
    }
    url += "&min=" + getUsageLevelValue(type, 0)
    url += "&max=" + getUsageLevelValue(type, 2)
    //url = "https://heyflow.id/bottone#screen-fb5651a9"; //TODO elimina
    //console.log("URL:", url);
    let iframe = shadowRoot.getElementById('iframe_compara');
    iframe.setAttribute('src', url);
    
    // add 16-09
    //let iframe_button = shadowRoot.getElementById('iframe_compara_button');
    //iframe_button.setAttribute('src', url);
}

function fillAction() {
    let action = sessionStorage.getItem("actionType");
    populateField('offerte-lista', INPUT_AZIONE_LABEL, action);
}

function showFormResidence(enterPage) {
    let element = shadowRoot.querySelector("section[name='fornitura-residenza'] input[type='radio']:checked");
    if(element == null || element.getAttribute('data-answer').toLowerCase() == VALUE_RESIDENCE_EQ_SUPPLY) {
        residenceVisibilty(false);
        if(! enterPage) {
            emptyResidenceData();
        }
    } else {
        if(! enterPage) {
            emptyResidenceData();
        }
        residenceVisibilty(true);
    }
}

function emptyResidenceData() {
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_CITY + "']").value = "";
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_PROVINCE + "']").value = "";
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_CAP + "']").value = "";
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_ADDRESS + "']").value = "";
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_HOUSE_NUMBER + "']").value = "";
}

function residenceVisibilty(show) {
    let section = shadowRoot.querySelector("section[name='fornitura-residenza']");
    var elements = section.querySelectorAll("div[data-blocktype='elements-v2']");
    for(var i = 0; i < 2; i++) {
        changeVisibilyHiddenElement(elements[i], show);
    }
    elements = section.querySelectorAll("div[data-blocktype='rich-text']");
    changeVisibilyHiddenElement(elements[1], show);
    elements = shadowRoot.querySelectorAll("section[name='fornitura-residenza']>div[data-blocktype='input']");
    changeVisibilyHiddenElement(elements[0], show);
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_CITY + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_PROVINCE + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_CAP + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_ADDRESS + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("input[data-label='" + INPUT_RESIDENCE_HOUSE_NUMBER + "']").setAttribute('data-initial-required', show)
}

function changeVisibilyHiddenElement(element, show) {
    if(show) {
        element.style = '';
        element.classList.remove('hide_desktop');
        element.classList.remove('hide_mobile');
    } else {
        element.style = 'display:none;';
        element.classList.add('hide_desktop');
        element.classList.add('hide_mobile');
    }
}

function showAddressContinueButton() {
    let section = shadowRoot.querySelector("section[name='fornitura-residenza']");
    var buttons = section.querySelectorAll("button[class~='continue']");
    for(var i = 0; i < 2; i++) {
        buttons[i].parentNode.parentNode.parentNode.style = 'display:none';
    }
    let type = sessionStorage.getItem("supplyType");
    if(type == "gas") {
        buttons[1].parentNode.parentNode.parentNode.style = '';
    } else if(type == "luce") {
        buttons[0].parentNode.parentNode.parentNode.style = '';
    }
}

function showFormIban(enterPage) {
    let element = shadowRoot.querySelector("section[name='iban'] input[type='radio']:checked");
    if(element == null || element.getAttribute('data-answer').toLowerCase() == VALUE_SENDER_EQ_OWNER) {
        ibanVisibilty(false);
        if(! enterPage) {
            emptyIbanData();
        }
    } else {
        if(! enterPage) {
            emptyIbanData();
        }
        ibanVisibilty(true);
    }
}

function emptyIbanData() {
    shadowRoot.querySelector("input[data-label='" + INPUT_IBAN_FIRSTNAME + "']").value = "";
    shadowRoot.querySelector("input[data-label='" + INPUT_IBAN_LASTNAME + "']").value = "";
    shadowRoot.querySelector("input[data-label='" + INPUT_IBAN_FISCAL_CODE + "']").value = "";
    shadowRoot.querySelector("select[data-label='" + INPUT_IBAN_BORN_OUT + "']").selectedIndex = 0
}

function ibanVisibilty(show) {
    let section = shadowRoot.querySelector("section[name='iban']");
    var elements = section.querySelectorAll("div[data-blocktype='input']");
    for(var i = 1; i < elements.length; i++) {
        changeVisibilyHiddenElement(elements[i], show);
    }
    elements = section.querySelectorAll("section[name='iban'] > div[data-blocktype='rich-text']");
    changeVisibilyHiddenElement(elements[3], show);
    elements = section.querySelectorAll("div[data-blocktype='select']");
    for(var i = 0; i < elements.length; i++) {
        changeVisibilyHiddenElement(elements[i], show);
    }
    shadowRoot.querySelector("input[data-label='" + INPUT_IBAN_FIRSTNAME + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("input[data-label='" + INPUT_IBAN_LASTNAME + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("input[data-label='" + INPUT_IBAN_FISCAL_CODE + "']").setAttribute('data-initial-required', show)
    shadowRoot.querySelector("select[data-label='" + INPUT_IBAN_BORN_OUT + "']").setAttribute('data-initial-required', show)
    //TODO sistema qui
}

function fixTextLastPage() {
    var type = sessionStorage.getItem("supplyType");
    if(! type) {
        type = "";
    } else if(type == "luce") {
        type = "GAS";
    } else {
        type = "LUCE";
    }
    fixButtonLastPage(type);
    fixTitleLastPage(type);
}

function fixButtonLastPage(type) {
    var element = shadowRoot.querySelector("section[name='confirm'] button span");
    var text = element.innerHTML;
    text = changeLastWord(text, type.toUpperCase());
    element.innerHTML = text;
}

function changeLastWord(text, newWord) {
    var index = text.lastIndexOf(" ");
    text = text.substring(0, index);
    text = text + " " + newWord;
    return text.trim();
}

function fixTitleLastPage(type) {
    var element = shadowRoot.querySelectorAll("section[name='confirm'] h3")[4];
    var text = element.innerHTML;
    text = changeLastWord(text, type.toLowerCase());
    element.innerHTML = text;
}

window.addEventListener('heyflow-screen-leave', (event) => {
    let page = event.detail.screenName;
    //console.log('heyflow screen ' + page + ' leave:', event);
    switch(page){
        case 'stima-consumo-luce':
            saveUsage(saveUsage, 'luce');
            saveSupplier(page);
            break;
        case 'stima-consumo-gas':
            saveUsage(saveUsage, 'gas');
            saveSupplier(page);
            break;
        case 'anagrafica':
            formatBirthDate();
            console.log(WS_CAMPAIGN_VALUE);
            break;
    }
});

function saveUsage(event, type) {
    let element = getUsageElement(type);
    let usage = parseInt(element.value);
    sessionStorage.setItem("usageValue", usage);
}

function saveSupplier(page) {
    let supplyType = sessionStorage.getItem("supplyType");
    let action = sessionStorage.getItem("actionType");
    let element = getSelectField(page, INPUT_ACTUAL_BRAND_PREFIX + supplyType);
    var value = "";
    if(!(action == 'subentro' || action == 'nuova')) {
        value = element.value;
    }
    populateField('offerte-lista', INPUT_ACTUAL_BRAND, value);
}

function formatBirthDate() {
    let source = getField('anagrafica', 'birthdate_dmy');
    let arrApp = source.value.split('/');
    let formatted = '' + arrApp[2] + '-' + arrApp[1] + '-' + arrApp[0];
    let destination = getField('anagrafica', 'birthdate');
    destination.value = formatted;
}

window.addEventListener('heyflow-submit', (event) => {
    //console.log('heyflow submit:', event.detail);
    //printSessionStorage();

});

window.addEventListener('heyflow-button-click', (event) => {
    //console.log('heyflow button click:', event.detail);

});

window.addEventListener('heyflow-change', (event) => {
    //console.log('heyflow change:', event.detail);
});

window.addEventListener('heyflow-input-click', (event) => {
    let page = event.detail.screenName;
    //console.log('heyflow ' + page + ' input click:', event.detail);
    switch(page){
        case 'start':
            clickPageStart(event);
            break;
        case 'gas-1':
        case 'luce1':
            clickPageType(event);
            break;
        case 'passato-luce':
        case 'passato-gas':
            clickPageChange(event);
            break;
        case 'fornitura-residenza':
            showFormResidence(false);
            break;
        case 'stima-consumo-luce':
            clickPageUsage(event, 'luce');
            break;
        case 'stima-consumo-gas':
            clickPageUsage(event, 'gas');
            break;
        case 'iban':
            showFormIban(false);
            break;
     }
});

function clickPageStart(event)  {
    let supplyType = event.detail.customEventName;
    sessionStorage.setItem("supplyType", supplyType);
}

function clickPageType(event)  {
    if(event.detail.customEventName == 'switch'){
        sessionStorage.setItem("actionType", 'switch');
    }
}

function clickPageChange(event) {
    sessionStorage.setItem("actionType", event.detail.customEventName);
}

function clickPageUsage(event, type) {
    var element = getUsageElement(type);
    element.value = event.detail.customEventName;
    let obj = getValueAndIndexBasedOnUsageType(type, element.value);
}